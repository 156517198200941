import { getCookie, setCookie, cooKeys, customEvent, ClickEventType } from "goi_common";

import { useRouter } from "next/router";
import { Axios } from "@/utils/axios";
import { quotationRequestState } from "@/atoms/quotationRequestState";
import { isLoggedInState } from "@/atoms/accountState";
// import { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

export const useHandleSendMemoAndRedirectToLogin = () => {
  const router = useRouter();
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const setQuotationRequest = useSetRecoilState(quotationRequestState);

  /** 정확히는 메모를 보내고 login page로 router.push 하는 거지 */
  const handleSendMemoAndRedirectToLogin = async ({
    path,
    memo,
  }: {
    path: ClickEventType["path"];
    memo?: string;
  }) => {
    /** common 기본 견적 제출 이벤트  */
    customEvent({
      newGtm: {
        path: "match_request",
        trigger_and_target: "mdl_check_result",
      },
      triggerHackle: true,
    });

    customEvent({
      newGtm: {
        path,
        trigger_and_target: "mdl_check_result",
      },
      triggerHackle: true,
    });

    const anyMemo = getCookie("match_request_memo");
    const previousRequestedQuotation = getCookie(cooKeys.quotation_request);

    // const 요청하고_싶은_점 =
    //   parlor.companyname +
    //   "개별 견적 페이지에서 제출한 견적 / 선택한 평형 : " +
    //   selectedOption.평형;

    /** 실제 상조 견적 요청을 보내는 부분 메모는 분기처리가 필요해 보인다. */
    const { data } = await Axios.patch(
      `/sangjo_quotation_requests/${previousRequestedQuotation.id}/`,
      {
        memo: memo + " | " + (anyMemo || ""),
      },
    );

    /** 로그인 유뮤에 따라서 다르게 쿠키를 다루고 로그인페이지로 보내는 부분 */
    if (!isLoggedIn) {
      setCookie(
        cooKeys.quotation_request,
        JSON.stringify({ ...data, id: previousRequestedQuotation.id }),
      );

      setQuotationRequest(data);

      router.push(
        `/login?redirectTo=${encodeURIComponent(`/match/request/`)}${`&event_from=${path}`}`,
      );
    } else {
      router.push(
        `/match/request-complete?id=${data.id}&sido=${data.funeral_sido}&sigungu=${data.funeral_sigungu}`,
      );
    }
  };

  return {
    handleSendMemoAndRedirectToLogin,
  };
};
